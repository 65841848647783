.lupa-container {
    position: relative;
    display: flex;
    align-items: center;
    
}

.search-icon {
    color: #000000; /* Lupa branca */
    cursor: pointer;
    font-size: 24px;
    line-height: 1;
}

.search-modal {
    position: absolute;
    top: 50px;
    right: -50px; /* Mova o modal mais para o canto direito */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 2000 !important;
    width: 300px;
    max-height: 400px;
    overflow-y: auto;
}

.search-input-wrapper {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}

.search-input {
    width: 100%;
    padding: 8px 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    font-size: 14px;
    outline: none;
    font-family: 'Poppins', sans-serif;
 
}

.search-results {
    margin-top: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px; 
    overflow-y: auto;
}

.search-results ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.search-result-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.search-result-item:last-child {
    border-bottom: none;
}

.search-result-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
}

.result-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 5px;
}

.result-name {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.search-result-link:hover {
    background-color: #f9f9f9;
}
