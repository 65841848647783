h2 {
  text-align: center;
  font-size: 26px;
  margin-top: 20px; /* Espaço no topo */
  margin-bottom: 40px; /* Espaço entre o título e o conteúdo */
  width: 100%;
  position: sticky; /* Fixa o título no topo */
  top: 0;
  background-color: white; /* Define uma cor de fundo para o h2 */
  z-index: 1000; /* Garante que o h2 fique sobre outros elementos */
  padding: 10px 0; /* Padding vertical para espaçamento */
}

.checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative; /* Necessário para o position absolute funcionar no resumo */
  padding-bottom: 200px; /* Espaço extra no final para acomodar o resumo */
}
/* Ajuste no conteúdo do checkout */
.checkout-content {
  display: flex;
  justify-content: space-between; /* Espaça os itens para que o resumo fique no lado direito */
  align-items: flex-start; /* Garante que ambos os elementos estejam alinhados ao topo */
  width: 100%;
  margin-top: -40px; /* Espaço entre o h2 e o conteúdo do checkout */
}

.customer-info, .checkout-form {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 30px;
  width: 65%; /* Define a largura do formulário para ocupar a maior parte do espaço */
  max-width: 800px; /* Limita a largura do formulário para centralizá-lo */
}

.customer-info h3, .checkout-form h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
}

.checkout-form button {
  justify-self: center;
}

.checkout-form input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.3s ease;
  background-color: #fafafa;
  margin-bottom: 15px;
}

.checkout-form input:focus {
  border-color: #000;
  outline: none;
}

.checkout-form input::placeholder {
  color: #999;
  font-size: 12px;
}

.field-full, .field-half {
  width: 100%;
  margin-bottom: 10px;
}

.field-half:last-of-type {
  margin-right: 0;
}

.checkout-form .section-identificacao,
.checkout-form .section-entrega {
  margin-bottom: 30px;
}

.order-summary {
  width: 30%;
  position: absolute;
  top: 90px; /* Ajuste do espaço do topo */
  right: 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-top: 0;
  z-index: 10; /* Garante que o resumo fique por cima de outros elementos */
}


.order-summary h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.order-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.order-item p {
  margin: 0;
  font-size: 0.9em;
}

.total {
  font-weight: bold;
  font-size: 20px;
  text-align: right;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 2px solid #ddd;
}

.checkout-actions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
/* Estilização das seções de opções de frete e forma de pagamento */
.shipping-info, .payment-info {
  text-align: center;
  margin-bottom: 30px;
}

.shipping-options, .payment-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.shipping-options label, .payment-options label {
  cursor: pointer;
  padding: 15px 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
}

.shipping-options label:hover, .payment-options label:hover {
  background-color: #f1f1f1;
}

.shipping-options label input, .payment-options label input {
  margin-right: 10px;
  accent-color: #000;
}

.shipping-options label span, .payment-options label span {
  font-size: 16px;
  color: #333;
}

.shipping-options label input:checked + span, .payment-options label input:checked + span {
  font-weight: bold;
  color: #000;
}

.shipping-options label input:checked, .payment-options label input:checked {
  outline: 2px solid #000;
}

.shipping-options label input:checked + span, .payment-options label input:checked + span {
  color: #000;
}

button {
  display: inline-block;
  width: auto;
  padding: 12px 20px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}


button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}


.payment-modal, .credit-card-modal, .pix-payment-modal {
  max-width: 600px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
}

.payment-modal h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.payment-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.payment-option {
  padding: 15px;
  border-radius: 8px;
  background-color: #f1f1f1;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.payment-option:hover {
  background-color: #ddd;
  transform: translateY(-2px);
}

.payment-option.selected {
  border: 2px solid #000;
  background-color: #e6f2ff;
}

.payment-qr-code {
  text-align: center;
  margin-top: 30px;
}

.payment-qr-code img {
  width: 250px;
  height: 250px;
  margin: 20px auto;
  display: block;
  border: 2px solid #ddd;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}



.pix-payment-info h3 {
  font-size: 22px;
  margin-bottom: 15px;
}

.pix-payment-info p {
  font-size: 16px;
  margin-bottom: 20px;
}

.pix-qr-container {
  margin: 20px 0;
  border: 2px solid #000;
  padding: 10px;
  border-radius: 10px;
  background-color: #f9f9f9;
  display: inline-block;
}

.pix-copy-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.pix-code {
  width: 100%;
  max-width: 500px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 10px;
  resize: none;
}

.copy-button {
  padding: 12px 20px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: rgb(40, 39, 41)
}

.pix-amount {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.credit-card-modal {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.credit-card-modal h3 {
  font-size: 22px;
  margin-bottom: 20px;
}

.credit-card-modal input,
.credit-card-modal select {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.credit-card-modal input:focus,
.credit-card-modal select:focus {
  border-color: #000;
  outline: none;
}

.credit-card-modal select {
  background-color: #f9f9f9;
}

.credit-card-modal button {
  width: 100%;
  padding: 15px;
  background-color: #353333;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.credit-card-modal button:hover {
  background-color: #000;
}

.credit-card-modal button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.credit-card-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.credit-card-fields .field-half {
  width: calc(50% - 15px);
}

.credit-card-fields .field-full {
  width: 100%;
}

.payment-success-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1000;
  max-width: 300px;
  font-family: 'Poppins', sans-serif;
}

.payment-success-message p {
  font-size: 1.2em;
  color: #4CAF50; /* Um verde agradável para indicar sucesso */
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}

.go-home-button {
  background-color: #4CAF50;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.go-home-button:hover {
  background-color: #45A049;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Consolidated media query for devices with a max-width of 768px */
@media (max-width: 768px) {
  .checkout-container {
    padding: 10px;
    margin: 0 auto; /* Center the container horizontally */
    max-width: 100%; /* Ensure full width on smaller screens */
  }

  .checkout-content {
    flex-direction: column;
    margin-top: 0;
    align-items: center; /* Center child elements */
  }

  .customer-info, .checkout-form {
    width: 100%;
    margin-bottom: 20px;
    align-items: center; /* Center child elements */
  }

  .order-summary {
    width: 100%;
    position: relative;
    margin-top: 20px;
    right: 0;
    top: auto;
    padding: 15px;
  }

  .order-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .total {
    text-align: left;
  }

  .shipping-options, .payment-options {
    flex-direction: column;
    align-items: center;
  }

  .payment-modal, .credit-card-modal, .pix-payment-modal {
    width: 90%;
    padding: 20px;
  }

  .credit-card-fields .field-half {
    width: 100%;
  }
}
