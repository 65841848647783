/* Estilização geral do carrinho */
.carrinho {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* Cabeçalho do carrinho */
.carrinho-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.carrinho-header h2 {
  font-size: 18px;
  color: #000000;
}

.close-btn {
  color: #000000;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

.close-btn:hover {
  opacity: 0.8;
}

/* Conteúdo do carrinho */
.carrinho-content {
  flex: 1;
  padding: 10px;
  overflow-y: auto; /* Para permitir o scroll do conteúdo */
  max-height: calc(100% - 150px); /* Altura dinâmica para que o footer fique fixo */
}

.carrinho-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.carrinho-item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.carrinho-item-info {
  flex: 1;
}

.carrinho-item-info h3 {
  font-size: 16px;
}

.carrinho-item-info .price {
  font-size: 14px;
  color: #000000;
}

/* Estilização dos botões de mais e menos */
.quantity-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-controls button {
  background-color: #000000;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.quantity-controls button:hover {
  background-color: #2c2b2b;
  transform: scale(1.1);
}

.quantity-controls span {
  font-size: 18px;
  margin: 0 10px;
  font-weight: bold;
}

/* Estilização do rodapé do carrinho */
.carrinho-footer {
  padding: 10px;
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 0;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); /* Sombra para o footer */
}

/* Estilização do total */
.carrinho-footer p {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 15px;
  text-align: center;
}

/* Estilização do botão de finalizar compra */
.checkout-btn {
  background-color: #000000;
  color: #fff;
  border: none;
  padding: 15px;
  width: 100%;
  cursor: pointer;
  font-size: 18px;
  border-radius: 30px;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.checkout-btn:hover {
  background-color:#383636; 
}

/* Ajustes para Mobile */
@media (max-width: 768px) {
  .carrinho {
    width: 80%;
    height: 100%;
    top: 0;
    right: 0;
  }

  .carrinho-header h2 {
    font-size: 16px;
  }

  .carrinho-content {
    padding: 5px;
  }

  .carrinho-item {
    padding: 8px 0;
  }

  .carrinho-item-image {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  .carrinho-item-info h3 {
    font-size: 14px;
  }

  .quantity-controls button {
    padding: 8px;
    font-size: 14px;
  }

  .quantity-controls span {
    font-size: 16px;
  }

  .checkout-btn {
    padding: 12px;
    font-size: 16px;
  }

  .carrinho-footer p {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .carrinho-footer {
    width: 80%; /* Ajusta o tamanho do footer para o tamanho do carrinho em mobile */
  }
}
