.produtos-filtrados {
    padding: 20px;
  }
  
  .produtos-filtrados h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Grid para os produtos */
  .produtos-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 colunas no desktop */
    gap: 20px; /* Espaçamento entre os itens */
    justify-content: center; /* Centraliza a grid */
    max-width: 1200px; /* Largura máxima do container */
    margin: 0 auto; /* Centraliza horizontalmente */
    padding: 20px;
  }
  /* Remover o sublinhado do Link */
.produto-card a {
    text-decoration: none; /* Remove o sublinhado */
    color: inherit; /* Garante que a cor do texto seja herdada (para o nome do produto e preço) */
  }
  
  /* Ajuste para telas menores (mobile) */
  @media (max-width: 600px) {
    .produtos-grid {
      grid-template-columns: repeat(2, 1fr); /* Exibe 2 colunas por linha no mobile */
      gap: 10px; /* Espaçamento reduzido no mobile */
    }
  }
  
  /* Estilo de cada produto */
  .produto-card {
    text-align: center;
   
    border: 1px solid #f8f8f8; /* Borda escura */
    padding: 10px;
    border-radius: 25px; /* Bordas arredondadas */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out; /* Efeito de hover */
  }
  
  /* Efeito de hover - aumenta o tamanho e suaviza a sombra */
  .produto-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Sombra mais forte no hover */
  }
  
  /* Estilo da imagem do produto */
  .produto-card img {
    max-width: 100%;
    height: auto;
    border-radius: 5px; /* Bordas arredondadas na imagem */
  }
  
  /* Estilo do nome do produto */
  .produto-card h2 {
    font-size: 18px;
    margin: 10px 0;
    color: #000000; /* Cor do texto */
  }
  
  /* Estilo do preço */
  .produto-card p {
    color: #a5a5a5; /* Cor mais clara para o preço */
    font-size: 16px;
  }
  